import React from "react"
import { validatePageYamlData } from "../../helpers/prop-types"
// @ts-ignore
import yaml from "../../data/how-to-set-up-marketing-analytics.yaml"
import Header from "../../components/sections/header"
import LayoutContainer from "../../components/layout/layout-container"
import { SectionBox } from "../../components/sections/section-box"
import TableSimple from "../../components/sections/table-simple"
import { Infobox } from "../../components/sections/infobox"

class HowToSetUpMarketingAnalytics extends React.Component {
  render() {
    validatePageYamlData(yaml)

    return (
      <LayoutContainer>
        <Header {...yaml.header} />
        <Infobox body={yaml.introduction.body} />
        <SectionBox>
          <TableSimple data={yaml.table} />
        </SectionBox>
      </LayoutContainer>
    )
  }
}

export default HowToSetUpMarketingAnalytics
